// MEDIA QUERY MANAGER

/* 
0 - 600px:          Phone - (phone)
600 - 900px:        Tablet Portrait - (tab-port)
900 - 1200px:       Tablet Landscape - (tab-land)
[1200 - 1800px]:    Normal Style Applies
1800px+:            Big Desktop - (big-desktop)
*/


// 1em = 16px

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content };    // 600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content };   // 900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content };      // 1200px
    }
    @if $breakpoint == big-desktop {
        @media (min-width: 90em) { @content };   // 1440px
    }
    @if $breakpoint == large-desktop {
        @media (min-width: 160em) { @content };   // 2560px
    }
}