.react-switch-bg {
    height: 25px !important;
    width: 52px !important;
}

.react-switch-handle {
    width: 20px !important;
    height: 20px !important;
    top: 2.5px !important;
}

.switch {
    background-color: rgb(0, 59, 40);
}