@import "styles/abstracts/_variables.scss";

.button {
    background-color: $primary-color;
    display: flex;
    outline: none;
    border: none;
    padding: 1.1rem 2.8rem;
    color: #fff;
    border-radius: 5px;
    font-family: $default-font;
    font-weight: 500;
    font-size: 1.2rem;
    transition: all 0.1s ease-in;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;

    &:hover {
        background-color: darken($primary-color, 5%);
    }
}