.brandingContainer {
 
  &__content {
    align-items: flex-start;
  }
}

.logoContainer {
  background-color: #FCFCFC;
  border: 0.1px solid #F2F2F2;
  padding: 2rem;
  width: 200px;
  height: 180px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;

  p {
    text-align: center;
    font-weight: 500;
    color: #747474;
  }

  img {
    width: 100%;
    max-height: 150px;
  }
}

.error {
  color: rgb(255, 52, 52);
  margin-bottom: 0.5rem;
}

.bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  right: 0;
  margin-bottom: 2rem;
  margin-right: 2rem;
}