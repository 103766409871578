@import "styles/abstracts/_variables.scss";

.inputContainer {
 display: inline-flex;
 flex-direction: column;
 width: 100%;
    
    label {
        font-size: 1.3rem;
        color: #363636;
        margin-bottom: 5px;
    }

    .required {
        color: #FF7A7A;
    }

    input {
        border: $input-border-color;
        outline: none;
        padding: 1.1rem 1rem;
        border-radius: 5px;
        height: 46px;
        font-size: 13px;
        transition: all 0.2s ease;
        font-family: $default-font;
        width: 100%;

        &:focus {
            border-color : $primary-color;
        }
    }

    .prefix {
        position: absolute;
        top: 1px;
        left: 1px;
        background-color: #e2e2e2;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 40px;
        height: calc(100% - 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        
        p {
            font-size: 1.5rem;
            color: #464646;
        }
    }

    
}