@import "styles/abstracts/_variables.scss";

.signInForm {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 1rem;
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
}

.bulkImportContainer {
    background-color: antiquewhite;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    
    p {
        font-size: 1.1rem;
    }

    .btn {
        border: 0.1px solid $secondary-color;
        border-radius: 5px;
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $secondary-color;

            P {
                color: #fff;
            }
        }

        p {
            color: $secondary-color;
        }
        
    }
}
  