@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";

.filterContainer {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;

    .inputContainer {
        display: flex;
        gap: 1rem;
        flex: 3;
    }

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        flex: 1.5;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 815px) {
    .filterContainer {
        flex-direction: column;
    }
    
    .inputContainer {
        width: 100%;
        flex: 0;
    }

    .buttonContainer {
        margin-top: 1rem;
        justify-content: flex-end;
        width: 100%;
    }
}

@media only screen and (max-width: 620px) {
    .inputContainer {
        flex-direction: column;
        gap: 0.5rem !important;
    }
}

