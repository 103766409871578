@import "styles/abstracts/_variables.scss";

.cardContainer {
    background-color: #fff;
    border-radius: 5px;
    padding: 2rem;
    -webkit-box-shadow: $default-shadow;
    -moz-box-shadow: $default-shadow;
    box-shadow: $default-shadow;
    position: relative;
}