@import "styles/abstracts/_variables.scss";

.container {
    // height: calc(100vh - 80px);
    background-color: #F8F9FB;
    // background-color: aliceblue;
    margin-top: 80px;
    padding: 2rem;
    margin-left: 300px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__warning {
        background-color: rgb(228, 179, 20);
        width: 100%;
        position: absolute;
        top: 0;
        padding: 1rem;
        
        p {
            color: #ffff;
            font-weight: 500;
            font-size: 1.4rem;
        }
    }

    &__content {
        width: 75%;
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    @media only screen and (max-width: 1880px) {
        &__content {
            width: 85%;
        }    
    }

    @media only screen and (max-width: 1600px) {
        &__content {
            width: 95%;
        }    
    }
}




@media only screen and (max-width: 990px) {
    .container {
        margin-left: 0;
    }    
}