.rolesTable {
    display: flex;

    .stickySidebar {
        width: 25%;
        background-color: #fcfcfc;
        border: 0.2px solid #eeeeee;

        .corner {
            min-height: 5rem;
        }

        .feature {
            margin-bottom: 4rem;
            &__header {
                font-size: 1.4rem;
                font-weight: 600;
                background-color: rgb(247, 247, 247);
                padding: 0.5rem 1rem;
            }

            &__permission {
                margin-bottom: 1rem;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 1rem;

                &__line1 {
                    font-weight: 400;
                    font-size: 1.3rem;
                }

                &__line2 {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .roles {
        width: 75%;
        display: flex;
        

        .permissions_role {

            border: 0.2px solid #eeeeee;
            border-left: none;

            &__header {
                font-size: 1.4rem;
                font-weight: 500;
                min-height: 5rem;
                display: flex;
                align-items: center;
                padding: 0 2rem;
            }

            &__section {
                padding-top: 2.9rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 3rem;

                &__checkbox {
                    margin-bottom: 1rem;
                    height: 50px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .permissions_role.admin {
            background-color: #f7fff8;
        }

        .permissions_role.standard {
            background-color: rgb(255, 255, 255);
        }
    }
}
