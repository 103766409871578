@import "styles/abstracts/_variables.scss";

.notificationOptions {
    margin-top: 3rem;
    display: flex;
    gap: 10rem;

    .events {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    // & > * {
    //     min-width: 250px
    // }
}