.gb-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    h2 {
        color: #212121;
        font-size: 1.6rem;
    }

    &__right {
        display: flex;
        gap: 1rem;
    }
}

.gb-head-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    .gb-head-navigation__back {
        color: #131313;
        font-size: 1.3rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        cursor: pointer;
    }

    .gb-head-navigation__breadcrumbs {
        display: flex;
        gap: 0.5rem;
        font-size: 1.2rem;

        &--link {
            color: #002547;
            cursor: pointer;
        }

        &--current {
            color: #808080;
        }
    }
}