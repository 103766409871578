@import "styles/abstracts/_variables.scss";

.tab__container {
    display: flex;
    justify-content: flex-start;
    box-shadow: $default-shadow;
    width: max-content;
    gap: 0.3rem;

    &__tab {
        background-color: rgb(241, 241, 241);
        padding: 1rem 2rem;
        font-size: 1.3rem;
        color: rgb(131, 131, 131);
        cursor: pointer;
    }

    &__tab:last-child {
        border-right: none;
        border-top-right-radius: 5px;
    }

    &__tab:first-child {
        border-top-left-radius: 5px;
    }

    &__tab.active {
        font-weight: 500;
        background-color: rgb(252, 252, 252);
        border-bottom: 2px solid $primary-color;
        color: $black;
    }

}

.tab__content {
    background-color: rgb(252, 252, 252);
    padding: 2rem;
    box-shadow: $default-shadow;
}