
// Theme Colors
$primary-color : #42BA78;
$secondary-color : #002547;

// Black Color
$black : #262626;
$input-border-color : 0.2px solid #DDDDDD;

// Font Family
$default-font : 'Poppins', sans-serif;

// Shadows
$default-shadow : -1px 1px 9px -5px rgba(41,41,41,0.33);