@import "styles/abstracts/_variables.scss";

.signInForm {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 1rem;
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
}