@import "styles/abstracts/_variables.scss";

.templateContainer {
    margin-top: 3.5rem;

    .templateViewContainer {
        background-color: #F2F2F2;
        padding: 1.5rem;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: max-content;
        min-width: 60%;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
            background-color: darken($color: #F2F2F2, $amount: 1.5);
        }

        .field {
            background-color: #fff;
            display: flex;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            align-items: center;

            .name {
                font-size: 1.3rem;
                color: $black;
            }

            .label {
                color: #fff;
                padding: 0.2rem 0.8rem;
                border-radius: 5px;
                font-size: 1.1rem;
            }

            .req {
                background-color: #FF5C5C;
            }

            .notReq {
                background-color: #037093;
            }
        }
    }
}


.templatesContainer {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;

    .template {
        background-color: #fff;
        padding: 3rem;
        border-radius: 5px;
        text-align: center;
        box-shadow: $default-shadow;
        border: 0.1px solid #f5f5f5;
        cursor: pointer;
        min-width: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $black;
        position: relative;
        

        &:hover {
            background-color: darken($color: #f5f5f5, $amount: 0.01);
        }

        .selected {
            background-color: #42BA78;
            color: #ffff;
            padding: 0.3rem 1rem;
            margin-top: 1rem;
            border-radius: 5px;
            position: absolute;
            top: -23px;
            right: 10px;
            font-size: 1rem;
        }

        h4 {
            font-size: 1.3rem;
            font-weight: 600;
        }
    }

    .addNew {
        background-color: #F9F9F9;
    }
}


.templateFormListContainer {
    margin-top: 2rem;

    .fields {
        margin-top: 2rem;
        max-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 1rem;


        .field {
            border: 0.1px solid #DDDDDD;
            padding: 1.3rem 1rem;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            cursor: pointer;
            top: auto !important;
            left: auto !important;
            margin-bottom: 1rem;

            &:hover {
                background-color: darken($color: #f8f8f8bd, $amount: 0.001);
            }

            .left {
                display: flex;
                align-items: center;
                gap: 2rem;

                .fieldName {
                    font-size: 1.3rem;
                }

                .labels {
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;

                    .label {
                        background-color: rgb(92, 92, 92);
                        padding: 0.2rem 0.8rem;
                        border-radius: 5px;
                        text-align: center;
                        color: #fff;
                    }
                }
            }

            

            .rightOptions {
                display: flex;
                gap: 3rem;
                align-items: center;

                .edit {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1.3rem;
                    color: #A0A0A0;
                }   
            }
        }

        .newFieldContainer {
            background-color: #EFEFEF;
            display: flex;
            gap: 1rem;
            padding: 1rem;
            border-radius: 5px;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }
}

.bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }