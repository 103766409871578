.gb-row {
    display: flex;
}

.gb-sp-btw {
    justify-content: space-between;
}

.gb-flex-end {
    display: flex;
    justify-content: flex-end;
}

.gb-gap-1 {
    gap: 1rem;
}

.text-center {
    text-align: center;
}

.flex-1 {
    flex : 1;
}


// Margins
.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}


.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.mb-5 {
    margin-bottom: 5rem;
}


.pad-x-1 {
    padding: 0 1rem;
}

.pad-x-2 {
    padding: 0 2rem;
}

.pad-x-3 {
    padding: 0 3rem;
}

.pad-x-4 {
    padding: 0 4rem;
}

.pad-x-5 {
    padding: 0 5rem;
}




// Alerts
.success-alert {
    background-color: rgb(230, 252, 230);
    width: 100%;
    display: flex;
    padding: 1rem;
    color: $primary-color;
    font-weight: 500;
    border-radius: 5px;

    p {
        font-size: 1.3rem;
    }
}

.warning-alert {
    background-color: rgb(253, 228, 194);
    width: 100%;
    display: flex;
    padding: 1rem;
    color: #9e7400;
    font-weight: 500;
    border-radius: 5px;

    p {
        font-size: 1.3rem;
    }
}

.error-alert {
    background-color: rgb(255, 223, 223);
    width: 100%;
    display: flex;
    padding: 0.8rem 1rem;
    color: #ce1d1d;
    font-weight: 500;
    border-radius: 5px;

    p {
        font-size: 1.3rem;
    }
}