@import "styles/abstracts/_variables.scss";

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
        width: 20%;
    }

    .right {
        width: 80%;
        display: flex;
        justify-content: flex-end;
    }
}

.tenantsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 2rem;

    .tenant {
        background-color: #FBFBFB;
        display: flex;
        border-radius: 10px;
        border: 0.1px solid #ececec;
        padding: 1rem;
        min-height: 80px;
        max-height: 80px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: darken($color: #FBFBFB, $amount: 1.1);
        }

        .tenantImg {
            display: flex;
            overflow: hidden;
            padding: 1rem;
            width: 100px;
            height: 70px;
            border-radius: 5px;
            overflow: hidden;
            

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: all 0.2s ease;
            }
        }

        .tenantName {
            font-size: 1.5rem;
            font-weight: 500;
            color: $black;
        }

        .status {
            background-color: $primary-color;
            padding: 0.5rem 2rem;
            color: #fff;
            border-radius: 10rem;
            margin-top: 1rem;
            font-size: 1.2rem;
        }
    }
}
