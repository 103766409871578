@import "/src/styles/abstracts/variables";

// .templateContainer {

//     .templateHeader {
        
//         padding: 1rem;
//         border-radius: 5px;
//         font-weight: 500;
//         font-size: 1.3rem;
//         margin-bottom: 1rem;
//     }

//     .global {
//         background-color: #f2fbff;
//         color: #037093;
//     }

//     .tenant {
//         background-color: #FFF3F3;
//         color: #D63F3F;
//     }

//     .templatesContainer {
//         display: flex;
//         margin-top: 2rem;
//         flex-wrap: wrap;
//         gap: 1rem;

//         .template {
//             background-color: #fff;
//             padding: 3rem;
//             border-radius: 5px;
//             text-align: center;
//             box-shadow: $default-shadow;
//             border: 0.1px solid #f5f5f5;
//             cursor: pointer;
//             min-width: 180px;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//             color: $black;

//             &:hover {
//                 background-color: darken($color: #f5f5f5, $amount: 0.01);
//             }

//             h4 {
//                 font-size: 1.3rem;
//                 font-weight: 600;
//             }
//         }

//         .addNew {
//             background-color: #F9F9F9;
//         }
//     }
// }

.templatesContainer {
    display: flex;
    gap: 1.5rem;
    margin-top: 3rem;

    .template {
        border: 0.1px solid #f3f3f3;

        .templateHeader {
            padding: 0.8rem 1rem;
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 1.5rem;
                font-weight: 450;
                color: #fff;
            }

            .addBtn {
                background-color: #fff;
                padding: 0.8rem 1rem;
                border-radius: 5px;
                cursor: pointer;
                color: $black;
                font-size: 1.2rem;
            }
        }

        &_content {
            padding: 1rem;

            &__info {
                font-size: 1.2rem;
                color: #4b4b4b;
                text-align: justify;
            }
        }
    }

    .globalTemplate {
        width: 50%;

        .templateHeader {
            background-color: rgb(60, 175, 112);

            p {
                color: #fff;
            }

            .addBtn {
                color: rgb(30, 146, 82);
            }

            
        }
    }

    .tenantTemplate {
        width: 50%;

        .templateHeader {
            background-color: #e45656;

            .addBtn {
                color: #e45656;
            }
        }
    }
}