@import "styles/abstracts/_variables.scss";

.heading {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;

    .horizontalLine {
        flex: 1;
        width: 100%; /* Adjust the width as needed */
        height: 1px; /* Adjust the height/thickness as needed */
        background-color: #ebebeb; /* Adjust the color as needed */
        
      }

      .settingHead {
        font-size: 1.4rem;
        font-weight: 500;
        color: $black;
    }
}

.featuresContainer {
    margin-top: 4rem;

    .featuresList {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .feature {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 250px;
            padding: 2rem 1rem;
            text-align: center;
            border-radius: 5px;
            box-shadow: $default-shadow;
            border: 0.1px solid #f8f8f8;

            &:hover {
                background-color: #fafafa;
            }

            .head {
                font-size: 1.4rem;
                margin: 1.5rem 0 0.5rem 0;
                font-weight: 600;
                color: $black;
            }

            .info {
                color: #2F2F2F;
                margin-bottom: 2rem;
                font-size: 1.2rem;
                
            }

            img {
                width: 40px;
            }

            .switch {
                display: flex;
                align-items: center;

                p {
                    color: $primary-color;
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
    
        }
    }
    
}

.signOutPolicyContainer {
    margin-top: 4rem;

    .form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

.badgePrintingContainer {
    margin-top: 4rem;

    .templatesContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 2rem;

        .tempate {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
            position: relative;

            .templateImage {
                position: relative;

                img {
                    width: 320px;
                }

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0);
                    transition: background 0.5s ease;
                }

                .buttons {
                    position: absolute;
                    opacity: 0;
                    top: 40%;
                    left: 35%;
                    display: flex;
                    gap: 1.5rem;
                    transition: opacity .35s ease;

                    .btn {
                        background-color: $primary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0.5rem;
                        border-radius: 3px;

                        &:hover {
                            background-color: darken($color: $primary-color, $amount: 2);
                        }
                    }
                }

                .selected {
                    background-color: $primary-color;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    margin-left: 1rem;
                    padding: 0.3rem 1rem;
                    border-radius: 5px;

                    p {
                        color: #fff;
                        font-size: 1.2rem;
                    }
                }
            }

            &:hover .overlay {
                display: block;
                background: rgba(0, 0, 0, .6);
            }

            &:hover .buttons {
                opacity: 1;
            }

            
        }
    }
}