.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .error_message {
        background-color: rgb(240, 240, 240);
        height: max-content;
        display: flex;
        align-items: center;
        padding-right: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 5px;

        &__right {
            h3 {
                font-size: 2rem;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }
}