@import "styles/abstracts/_variables.scss";

.templateFormContainer {
    
    display: flex;
    gap: 2rem;

    .templateEditor {
        padding: 0 2rem;
        width: 70%;
    }

    .templateInfoContainer {
        display: flex;
        gap: 2rem;
    }

    .templateFormListContainer {
        margin-top: 1rem;
        margin-bottom: 5rem;

        .fields {
            margin-top: 1.5rem;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            &__constant {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .draggableContainer {
                margin-top: 2.5rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }


            .field {
                border: 0.1px solid #b3e0bb;
                padding: 1rem 2.5rem;
                border-radius: 150px;
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                cursor: pointer;
                top: auto !important;
                left: auto !important;
                position: static !important;

                &:hover {
                    background-color: darken($color: #f8f8f8bd, $amount: 0.001);
                }

                .left {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;
                    
                    &__top {
                        display: flex;
                        gap: 1rem;
                    }

                    .label {
                        font-size: 1rem;
                    }
                    
                    .fieldName {
                        font-size: 1.3rem;
                        font-weight: 400;
                        color: #005710;
                    }

                    .labels {
                        display: flex;
                        align-items: center;
                        gap: 0.4rem;

                        .label {
                            background-color: rgb(92, 92, 92);
                            padding: 0.2rem 0.8rem;
                            border-radius: 5px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }

                

                .rightOptions {
                    display: flex;
                    gap: 3rem;
                    align-items: center;
                    justify-content: center;

                    .checkbox_container {
                        margin-bottom: 0;
                    }

                    .edit {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        font-size: 1.3rem;
                        color: #A0A0A0;
                    }   
                }
            }

            .newFieldContainer {
                background-color: #F9F9F9;
                display: flex;
                gap: 1rem;
                padding: 1rem;
                border-radius: 5px;
                margin-bottom: 2rem;
                align-items: baseline;
                margin-top: 2rem;
                position: relative;

                .cancel {
                    position: absolute;
                    bottom: -3rem;
                    right: 0;
                    color: #ca3c3c;
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                }
            }
        }

        .addFieldTxtBtn {
            color: $secondary-color;
            font-weight: 500;
            font-size: 1.4rem;
            cursor: pointer;
            padding: 0.8rem 1.2rem;
            display: inline;
            border: 0.1px solid rgb(255, 255, 255);

            &:hover {
                background-color: rgb(241, 243, 255);
                border-radius: 20px;
                border: 0.1px solid rgb(156, 165, 216);
            }
        }
    }

    .assignedTenantsContainer {
        
        .tenantsList {
            margin-top: 2rem;
            gap: 1rem;
            display: flex;
            flex-direction: column;
        }
    }
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
}