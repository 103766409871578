@import "styles/abstracts/_variables.scss";

.active_signin_form {
    border: 1px solid rgb(2,148,67);
    // background: linear-gradient(90deg, rgba(2,148,67,1) 0%, rgba(126,210,164,1) 100%);
    width: fit-content;
    min-width: 100%;
    max-width: 100%;
    border-radius: 5px;
    transition: all 0.3s ease-in;

    // &:hover {
    //     background: linear-gradient(150deg, rgba(2,148,67,1) 0%, rgba(126,210,164,1) 100%);
    // }

    &__header {
        background: linear-gradient(90deg, rgba(2,148,67,1) 0%, rgba(126,210,164,1) 100%);
        padding: 1.5rem;
    }

    &__title {
        color: #ffff;
        font-size: 1.6rem;
        font-weight: 600;
    }

    &__label {
        background-color: #e79702;
        padding: 0.5rem 1rem;
        border-radius: 5px;

        p {
            color: #ffff;
            font-weight: 500;
        }
    }

    &__fields {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        &__field {
            background-color: #fafafa;
            display: flex;
            gap: 1rem;
            padding: 0.5rem 0.8rem;
            border-radius: 5px;
            border: 0.1px solid #dfdfdf;
            // box-shadow: 4px 4px 6px -4px rgb(128, 128, 128);
            align-items: center;
            font-weight: 500;

            &__required {
                color: #D63F3F;
                margin-left: 0.5rem;
            }

            &__label {
                background-color: #FF5C5C;
                padding: 0.5rem;
                border-radius: 5px;
                color: #fff;
                font-weight: 400;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
            }
        }
    }

    &__infoBottom {
        padding: 0 1rem 1rem 1rem;

        .required {
            color: #D63F3F;
        }
    }
}

.templatesContainer {
    margin-top: 4rem;

    &__options {
        display: flex;
        gap: 1rem;

        &__option {
            background-color: #FF5C5C;
            padding: 1rem 2rem;
            border-radius: 10rem;
            font-size: 1.3rem;
            background-color: #F8F8F8;
            border: 0.1px solid #C5D7E5;
            color: #737373;
            font-weight: 500;
            cursor: pointer;
        }
        
        &__option.tenant {
            background-color: #DDE8F1;
            border: 0.1px solid #C5D7E5;
            color: #002547;
        }

        &__option.global {
            background-color: #FFEEEE;
            border: 0.1px solid #E7CCCC;
            color: #D63F3F;
        }
    }

    &__content {
        margin-top: 2rem;
        width: 100%;

        &__info {
            color: $black;
        }

        &__addNewBtn {
            background-color: #037093;
            padding: 1rem 1.5rem;
            color: #f8f8f8;
            border-radius: 5rem;
            border: none;
            font-family: $default-font;
            font-weight: 500;
            font-size: 1.3em;
        }


    }

   
}

.expandRowContainer {
    background-color: #f5f5f5;
    padding: 1rem 1.5rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    &__field {
        background-color: #fafafa;
        display: flex;
        gap: 1rem;
        padding: 0.5rem 0.8rem;
        border-radius: 5px;
        box-shadow: 4px 4px 6px -4px rgb(128, 128, 128);
        align-items: center;
        font-weight: 500;
        border: 0.1px solid #e9e9e9;

        &__required {
            color: #D63F3F;
            margin-left: 0.5rem;
        }

        &__label {
            background-color: #FF5C5C;
            padding: 0.5rem;
            border-radius: 5px;
            color: #fff;
            font-weight: 400;
            font-size: 1.1rem;
            letter-spacing: 0.1rem;
        }
    }
}

.roundedBtn {
    background-color: #fff;
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-family: $default-font;
    border-radius: 5rem;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: $primary-color;
        color: #fff;
    }
}

.activeLabel {
    background-color: $primary-color;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
}