@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";


.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        width: 20%;
    }

    .right {
        width: 80%;
        display: flex;
        justify-content: flex-end;
    }
}

.sitesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    gap: 2rem;

    .site {
        background-color: #FBFBFB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 1.5rem 2rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: darken($color: #FBFBFB, $amount: 1.1);
        }

        &:hover .siteImg img {
            transform: scale(1.05);
        }

        &:hover p {
           font-weight: 500;
        }

        .siteImg {
            width: 150px;
            height: 130px;
            display: flex;
            overflow: hidden;
            padding: 1rem;
            

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: all 0.2s ease;
            }
        }

        p {
            font-size: 1.4rem;
            font-weight: 500;
            color: $black;
            transition: all 0.2s ease;
        }
    }
}

.loader {
    
}