@import "styles/abstracts/_variables.scss";

.confirmDeliveryModalContainer {
    display: flex;
    gap: 3rem;
    padding: 0 2rem;
    margin-top: 2rem;
    justify-content: flex-end;
    align-items: flex-start;

    .deliveryImageContainer {
        width: 350px;
        height: 350px;
        display: flex;
        align-items: center;
        padding: 1rem;
        border: 0.1px solid #e7e7e7;
        border-radius: 5px;

        img {
            border-radius: 5px;
        }
    }

    .inputsContainer {
        width: 100%;

        p {
            font-size: 1.3rem;
        }

        .inputs {
            display: flex;
            margin-top: 3rem;
            flex-direction: column;
        }
    }
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
}