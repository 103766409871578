@import "styles/abstracts/_variables.scss";

.radio {
    display: inline-flex;
    align-items: center;
    gap: 1rem;

    label {
        font-size: 1.3rem;
    }
}

