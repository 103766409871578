.approvedSites {
    margin-top: 2rem;
    
    p {
        color: #363636 !important;
        font-size: 1.3rem !important;
    }

    .buildingsContainer {
        
        margin-top: 1rem;
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p {
            background-color: #f5f5f5;
            padding: 0.5rem;
            font-weight: 500;
            border-radius: 5px;
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                background-color: #45BB7A;
                position: absolute;
                top: 35%;
                left: 5px;
                border-radius: 50%;
            }
        }

    }
}

.addNewUserTypeBtn {
    display: flex;
    flex: 1;

    p {
        background-color: #e9fff3;
        padding: 1.1rem 1rem;
        border-radius: 5px;
        color: #45BB7A;
        font-size: 1.2rem;
        font-weight: 500;
        border: 0.2px solid #77e2a7;
        cursor: pointer;

        &:hover {
            background-color: darken($color: #e9fff3, $amount: 4);
        }
    }
}