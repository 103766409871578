.content {

    .steps {
         font-weight: 400;
         
         p {
            font-size: 1.3rem;
            margin-bottom: 1rem;
         }
    }
}