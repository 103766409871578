@import "styles/abstracts/_variables.scss";

.header {
    background-color: white;
    padding: 2rem;
    min-height: 80px;
    max-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;

    .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        .logo {
            width: 200px;
        }
    }

    .right {
        display: flex;
        gap: 3rem;

        .evacuate {
            background-color: #FFEEEE;
            padding: 0.1rem 1rem;
            border-radius: 15rem;
            font-size: 1.3rem;
            color: #D63F3F;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            cursor: pointer;
            transition: all 0.1s ease-in;

            &:hover {
                background-color: #fde9e9;
            }

            img {
                width: 2rem;
            }
        }

        

        .user {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            font-weight: 400;
            color: $black;
            cursor: pointer;

            p {
                font-size: 1.4rem;
            }
        }
    }

    .arrow-down {
        font-size: 1rem;
    }

    
}

.building {
    background-color: #E7FFF2;
    padding: 0.6rem 1.4rem;
    border-radius: 15rem;
    font-size: 1.3rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.1s ease-in;
    color: $black;
    position: relative;

    &:hover {
        background-color: #dcfceb;
    }

    img {
        width: 2rem;
    }
}

.buildingListContainer {
    // border: 0.1px solid #ececec;
    
    max-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        li {
            font-size: 1.4rem;
            padding: 1.2rem 1.5rem;
            text-align: left;
            // border-bottom: 0.1px solid #ececec;
            width: 100%;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            &:hover {
                background-color: #f7f7f7;
                font-weight: 450;
                color: $secondary-color;
            }

            span {
                background-color: #edf6ff;
                font-size: 0.9rem;
                font-weight: 500;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                color: $secondary-color;
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
    
    
}