@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";


.invitedUsersContainer {
    background-color: #EEF7FF;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    
    p {
        font-size: 1.4rem;
        color: $secondary-color;
        font-weight: 500;
    }
}


@media (max-width: 1050px) {

    .left {
        width: 70% !important;
    }
        
}

@media (max-width: 640px) {

    .filterContainer {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .left {
        width: 100% !important;
    }

    .invitedUsersContainer {
        margin-top: 1rem;
    }
        
}

@include respond('phone') {
    .left {
        flex-direction: column;
    }
}