.form {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.uploadedFile {
    background-color: rgb(132, 175, 204);
    padding: 1rem;
    color: #fff;
    border-radius: 5px;
     
    p {
        color: #fff;
    }
}