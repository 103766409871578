@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";

.siteDetailsForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bottomContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;
    margin-right: 2rem;
    right: 0;

    .buttons {
        display: flex;
        gap: 1rem;
    }
}