
.bodyContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
}

.footer {
    display: flex;
    justify-content: flex-end;
}