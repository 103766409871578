.dropdownContainer {
    position: relative;
}

// .dropdownHeader {
//     position: relative;
// }

.dropdownContent {
    position: absolute;
    top: 4rem;
    right: 0;
    min-width: 300px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:  0px 0px 5px 0px rgba(201, 201, 201, 0.75);
}