@import "styles/abstracts/_variables.scss";

.collectionBody {
    .parcel {
        background-color: aliceblue;
        margin-top: 1rem;
        padding: 1rem;
        border-radius: 5px;
    }
}

.footerContainer {
    display: flex;
    justify-content: space-between;

    .right {
        display: flex;
        gap: 1rem;
    }
}