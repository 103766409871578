@import 'styles/abstracts/_variables.scss'; 

.container {
   padding: 0 1rem;

    p {
        font-size: 1.1rem;
    }

    .authorizationContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

        h4 {
            font-size: 1.4rem;
            color: $black;
        }

        .authCode {
            background-color: #F2F2F2;
            padding: 1rem;
            width: 50%;
            font-size: 2rem;
            letter-spacing: 1rem;
            text-align: center;
            font-weight: 500;
            color: #484848;
            margin: 1rem 0;
            border-radius: 5px;
        }
    }
}