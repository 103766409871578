@import "styles/abstracts/_variables.scss";

.inputContainer {
 display: inline-flex;
 flex-direction: column;
 width: 100%;
    
    label {
        font-size: 1.3rem;
        color: #363636;
        margin-bottom: 5px;
    }

    .required {
        color: #FF7A7A;
    }
    
}

.customSelect {
    .css-1fdsijx-ValueContainer {
        padding: 0.9rem 1rem !important;
    }
}