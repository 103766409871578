.css-13cymwt-control {
    border: $input-border-color !important;
}

.css-olqui2-singleValue {
    font-size: 1.2rem;
    color: #696969 !important;
}

.css-1jqq78o-placeholder {
    font-size: 13px !important;
}

.css-qbdosj-Input, .css-1dimb5e-singleValue {
    font-size: 13px !important;
    font-family: $default-font !important;
}

.css-t3ipsp-control {
    border: solid 0.1px $input-border-color !important;
    box-shadow: none !important;
    border-color: $primary-color !important;
}

.css-1fdsijx-ValueContainer {
    padding: 0.7rem 1rem !important;
}

.css-1phn1ih-control {
    border-color: $primary-color !important;
    box-shadow: none;
}

.css-1nmdiq5-menu {
    font-size: 1.2rem;
    z-index: 2000 !important;
}

.error-outline {
    border-color: #e22020 !important;
    
    // For Select Input
    .css-13cymwt-control {
        border : 0.2px solid #e22020 !important
    }
}

.errorMessage {
    margin-top: 0.5rem;
    text-align: end;
    color: #e22020;
    opacity: 0;
    content: "";
    transition: all 0.2s ease;
}

.errorMessage.active {
    opacity: 1;
}

.rs-picker-toggle-wrapper {
    position: relative;
}

.rs-picker-date-menu {
    z-index: 10000000000 !important; /*added a random number*/
    top: 100;
}

// Multi Select Input
.css-wsp0cs-MultiValueGeneric {
    font-size: 1.2rem !important;
}

.css-3w2yfm-ValueContainer {
    padding: 6px 8px !important;
}


// Date Range
.date-range {
    z-index: 1000 !important;
    position: relative;
}

.rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background-color: $primary-color !important;
}

.rdrMonth, .rdrMonthAndYearWrapper {
    background-color: #fff !important;
}

.rdrMonthAndYearWrapper {
    border: 0.1px solid #ececec;
    border-bottom: none;
}

.rdrMonth {
    border: 0.1px solid #ececec;
}