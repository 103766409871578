@import "styles/abstracts/_variables.scss";

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 0.1px solid #EEEEEE;
    position: sticky;
    top: 0;
    background-color: #fff;

    h2 {
        font-size: 1.4rem;
        font-weight: 600;
        color: $black;    
    }

    .closeIcon {
        display: flex;
        cursor: pointer;
    }
}

.modalBody {
    max-height: 70vh;
    overflow-y: scroll;
    margin: 2rem;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e4e4e4; /* Background color of the scrollbar handle */
        border-radius: 5px; /* Rounded corners for the handle */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #acacac; /* Background color of the scrollbar handle on hover */
    }
}

.modalFooter {
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.3rem 1.5rem;
    border-top: 0.1px solid #EEEEEE;
}