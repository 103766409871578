.rdt_Table {
    font-family: $default-font !important;

    .rdt_TableHeadRow {
        background-color: #FAFAFA !important;
        border: none !important;
        border-radius: 5px !important;
        min-height: 40px;
        font-size: 1.3rem !important;
      
        .rdt_TableCol {
            color: #212121;
        }

      }

      .rdt_TableRow {
        border: none !important;
        margin-top: 1rem;
        font-size: 1.3rem !important;

        &:hover {
            background-color: #f1f1f1;
        }
    }

}