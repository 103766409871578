@import "styles/abstracts/_variables.scss";

.switchContainer {
    display: flex;
    align-items: center;
    gap: 1rem;

    label {
        font-size: 1.2rem;
    }
}