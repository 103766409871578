p {
    font-size: 1.3rem;
    color: #262626;
}

h1 {
    color: green;
}

h2 {
    line-height: 0;
}

.text-reg {
    font-size: 1.3rem;
}

.text-black-light {
    color: #262626;
}

.gb-head-light {
    font-size: 1.3rem;
    font-weight: 500;
}

.gb_title {
    font-weight: 600;
    font-size: 1.4rem;
    color: $black;
}

.gb_subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: $black;
}

.text-bold {
    font-weight: 600;
}

.faded-text {
    color: #888888;
}

.link {
    color: $secondary-color;
    font-weight: 500;
    cursor: pointer;
}

.warning-text {
    background-color: rgb(255, 230, 183);
    color: rgb(121, 78, 0);
    padding: 0.5rem;
    border-radius: 3px;
    font-weight: 400;
}