@import "styles/abstracts/_variables.scss";

.sidebar {
    position: fixed;
    background-color: white;
    width: 300px;
    height: 100%;
    margin-top: 80px;
    box-sizing: border-box;
    transition: all .3s ease-in;
    left: 0px;
    z-index: 999;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .mainMenu {
        margin-top: 20%;
        width: 100%;
        padding-bottom: 200px;
    }
}

@media only screen and (max-width: 990px) {
    .sidebar {
        display: none;
    }    
}