.pagination {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    font-size: 1.3rem;

    .page-link {
        padding: 0.8rem 1.4rem;
        border-radius: 5px;
    }

    .page-item {
        cursor: pointer;
    }

    .page-item.disabled {
        color: #c4c4c4;
    }

    .page-item.active {

        .page-link {
            background-color: $primary-color !important;
            color: #fff;
        }
        
    }

    
} 