@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 70%;
    height: 100%;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    position: relative;
    background-color: #F8F9FB;
}

#root {
}

a {
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

ul {
    li {
        list-style: none;
    }
}

.Toastify__toast--success {
    background:  #45BB7A !important;
}

.Toastify__toast-body {
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.2rem !important;
}
