@import "styles/abstracts/_variables.scss";

.detailsHead {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 0 1rem;

    .left {

        display: flex;
        align-items: center;

        img {
            border-radius: 50%;
            width: 95px;
            height: 95px;
        }
    }

    .right {
        flex: 1;

        h3 {
            font-size: 1.6rem;
            font-weight: 600;
            color: $black;
        }

        .infoContainer {
            display: flex;
            margin-top: 1rem;
            gap: 3rem;

            .signInInfoBox {

                h4 {
                    color: #535353;
                    font-weight: 300;
                    font-size: 1.4rem;
                }

                p {
                    font-size: 1.3rem;
                }
            }
        }

        
    }
}

.detailsBody {
    margin-top: 4rem;
    padding: 0 1rem;

    .infoItem  {
        display: flex;
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 1rem;

        .infoTitle {
            background-color: #EDEDED;
            width: 25%;
            padding: 1rem;
        }
        
        .infoContent {
            background-color: #F8F8F8;
            width: 75%;
            padding: 1rem;
        }
    }
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
}