.bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }

  .uploadedFile {
    background-color: rgb(132, 175, 204);
    padding: 1rem;
    color: #fff;
    border-radius: 5px;
     
    p {
        color: #fff;
    }
}