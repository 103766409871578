@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";

.deliveriesContainer {
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    @include respond('tab-port') {
        flex-direction: column;
    }

    .cardHeader {
        background-color: aliceblue;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 1.6rem 2rem;
        font-size: 1.3rem;
        font-weight: 500;
        color: #0A9C4C;
        background-color: #E5F5EC;
    }

    .unassigned {
        background-color: #FFEEEE;
        color: #B00000
    }

    .cardBody {
        margin-top: 5rem;

        .inputContainer {
            display: flex;
            width: 50%;

            @include respond('phone') {
                width: 100%;
            }
        }
    }
}

// Access all child flex elements
.deliveriesContainer>* {
    @include respond('tab-port') {
        flex : 1;
        width: 100%;
    }
}