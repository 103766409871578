@import "styles/abstracts/_variables.scss";

.customDropDown {
  background-color: #E3E3E3;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 5px;
  color: $black;
  display: flex;
  gap: 0.8rem;
  align-items: center;
}