@import "styles/abstracts/_variables.scss";

.confirmDeliveryModalContainer {
    display: flex;
    gap: 3rem;
    padding: 0 2rem;
    margin-top: 2rem;

    .deliveryImageContainer {
        width: 350px;
        height: 350px;
        display: flex;
        align-items: center;
        padding: 1rem;
        border: 0.1px solid #e7e7e7;
        border-radius: 5px;

        img {
            border-radius: 5px;
        }
    }

    .inputsContainer {
        width: 60%;

        p {
            font-size: 1.3rem;
        }

        .inputs {
            display: flex;
            margin-top: 3rem;
            flex-direction: column;
            gap: 2rem;
        }

        .otherInfo {
            display: flex;
            gap: 5rem;
            margin-top: 2rem;

            h4 {
                font-size: 1.3rem;
                font-weight: 500;
                color: #535353;
            }

            p {
                color : #535353
            }
        }

        .resendLink {
            color: #002547;
            margin-top: 2.5rem;
            font-size: 1.3rem;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.footerContainer {
    display: flex;
    justify-content: space-between;

    .right {
        display: flex;
        gap: 1rem;
    }
}