.loader {
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 300px;
    }
}