.notificationOptions {
    margin-top: 3rem;
    display: flex;
    gap: 10rem;

    // & > * {
    //     min-width: 250px
    // }
    .events {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.receiverEmails {
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;

    .email {
        display: inline-flex;
        padding: 0.6rem 2rem;
        border-radius: 20px;
        gap: 2rem;
        align-items: center;
        background-color: #f2f2f2;
    }
}