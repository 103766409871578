.authContainer {
    display: flex;
    height: 100%;
    background-color: #fff;

    .left {
        width: 40%;
        height: 100vh;
        position: fixed;

        @include respond('tab-port') {
            display: none;
        }

        .background {
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, rgba(33, 156, 88, 0.342), rgba(0, 53, 24, 0.575)), url('../../assets/images/login\ bg.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .right {
        width: 60%;
        margin-left: 40%;
        background-color: #fff;
        display: flex;
        align-items: center;
        min-height: 100vh;
        justify-content: flex-start;
        padding: 5rem 10%;

        @include respond('tab-land') {
            padding: 5rem 10rem;
        }

        @include respond('tab-port') {
            width: 100%;
            margin: 0;
        }

        @include respond('phone') {
            padding: 5rem 2rem;
        }

        .content {
            width: 100%;

            .logo {

                display: flex;
                justify-content: center;
                margin-bottom: 8rem;

                img {
                    width: 350px;

                    @include respond('phone') {
                        width: 250px;
                    }
                }

            }

            .back {
                display: inline-flex;
                color: $black;
                cursor: pointer;
                align-items: center;
                gap: 1rem;
                font-size: 1.3rem;
            }

            .head {
                margin: 2rem 0 2rem 0;
                width: 70%;

                @include respond('phone') {
                    width: 100%;
                }

                .heading {
                    font-weight: 600;
                    font-size: 1.6rem;
                }

                .info {
                    font-size: 1.2rem;
                }
            }

            .forget-password {
                text-align: end;
                color: $black;
                font-size: 1.2rem;

                span {
                    font-weight: 500;
                    color: $secondary-color;

                    a {
                        cursor: pointer;
                    }
                }

            }

            .passwordReq {
                background-color: rgba(245, 245, 245, 0.493);
                margin-bottom: 1rem;
                padding: 1rem;
                border-radius: 5px;
            
                p {
                    font-weight: 500;
                }
            
                ul {
                    li {
                        list-style: circle !important;
                        margin-left: 2rem;
                        position: relative;

                        span {
                            margin-left: 0.5rem;
                            top : 0.1rem;
                            position: absolute;
                        }
                    }
                    
                }
            }

            
        }
    }

}