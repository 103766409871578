@import "styles/abstracts/_variables.scss";

.subMenuLayoutContainer {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    position: relative;

    .subMenuContainer {
        background-color: rgb(255, 255, 255);
        width: 18%;
        position: sticky;
        top: 100px;

        .menu {
            border-bottom: 0.1px solid #F3F3F3;
            padding: 1.5rem 2rem;
            font-size: 1.25rem;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        .menuActive {
            background-color: #F8FFFB;
            color: $primary-color;
            font-weight: 500;
            border-left: 5px solid $primary-color; /* Outer border */
            position: relative;
        }

        .menuActive::before {
            content: "";
            position: absolute;
            top: 0;
            left: -2px; 
            height: 100%;
            width: 2px;
        }
    }

    .mainContainer {
        background-color: rgb(255, 255, 255);
        width: 82%;
        padding: 3rem;
        min-height: 65vh;
        position: relative;
        margin-bottom: 5rem;
    }
}