@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";

.optionsContainer {
    display: flex;
    gap: 2rem;

    .option {
        background-color: #ffff;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        cursor: pointer;
        min-width: 200px;
        border-radius: 5px;
        transition: all 0.2s ease;

        &:hover {
            background-color: darken($color: #fdfdfd, $amount: 0.5);
        }

        img {
            width: 70px;
            height: 70px;
        }

        p {
            font-size: 1.3rem;
            font-weight: 500;
            color: $secondary-color;
        }
    }
}