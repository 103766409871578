.body {
    display: flex;
    gap: 2rem;
    padding: 0.5rem;

    .options {
        width: 25%;

        ul {
            li {
                
                padding: 2rem 1.2rem;
                font-size: 1.2rem;
                border-left: 5px solid #ffffff;
                cursor: pointer;

                &:hover {
                    background-color: #fcfcfc;
                    border-left: 5px solid #fcfcfc;
                }
            }

            li.active {
                background-color: #f1fdf6;
                font-weight: 500;
                color: #42BA78;
                border-left: 5px solid #42BA78;
            }
        }
    }

    .form {
        width: 75%;

        table {

            th {
                text-align: left;
                font-size: 1.3rem;
                font-weight: 500;
                margin-bottom: 1rem;
                padding: 0rem 0.5rem;
            }

            td {
                font-size: 1.2rem;
                padding: 0.5rem 0.5rem;
            }
        }

        .contactEmailContainer {
            margin-bottom: 2rem;
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;

            .email {
                background-color: #f3f3f3;
                padding: 0.2rem 1rem;
                border-radius: 3px;
                display: flex;
                align-items: center;
                gap: 1rem;
            }   
        }
    }
}

.notificationOptions {
    margin-top: 3rem;
    display: flex;
    gap: 6rem;

    // & > * {
    //     min-width: 250px
    // }
    .events {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}