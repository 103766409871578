@import "styles/abstracts/_variables.scss";

.inputContainer {
 display: inline-flex;
 flex-direction: column;
 width: 100%;
 position: relative;
    
    label {
        font-size: 1.3rem;
        color: #363636;
        margin-bottom: 5px;
    }

    .required {
        color: #FF7A7A;
    }

    input {
        border: $input-border-color;
        outline: none;
        padding: 1.1rem 1rem;
        border-radius: 5px;
        font-size: 13px;
        transition: all 0.2s ease;
        font-family: $default-font;
        width: 100%;

        &:focus {
            border-color : $primary-color;
        }
    }

    ul {
        background-color: #fcfcfc;
        border: 0.1px solid #ebebeb;
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 280px;
        overflow-y: scroll;
        z-index: 999;

        li {
            padding: 1rem;
            font-size: 1.2rem;
            font-weight: 400;

            &:hover {
                background-color: darken($color: #fcfcfc, $amount: 2.5);
            }
            
            &:not(:last-child) {
                border-bottom: 0.1px solid #ebebeb;
            }
        }

        li.createField {
            background-color: #e3f1eb !important;
            color: #379774;
            cursor: pointer;
        }

        li.createField:hover {
            background-color: #7aa192 !important;
            color: #fff;
        }
    }

    
}