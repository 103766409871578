.statContainer {
    display: flex;
    margin-bottom: 3rem;
    flex-wrap: wrap;
    gap: 2rem;

    &__stat {
        background-color: #ffffff;
        display: flex;
        padding: 1.6rem;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        flex: 1;

        &__left {

            h3 {
                font-size: 3rem;
                color: #212121;

                @media only screen and (max-width: 1350px) {
                    font-size: 2.3rem;
                }
            }
            

            p {
                font-size: 1.3rem;
                color: #212121;

                @media only screen and (max-width: 1880px) {
                    font-size: 1.1rem;   
                }
            }
        }

        &__right {

            &_icon {
                background-color: #E3FDEE;
                padding: 1rem;
                border-radius: 5px;
            }

            img {
                width: 30px;

                @media only screen and (max-width: 1880px) {
                    width: 20px;
                }
            }
        }
    }
}

.main {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 4rem;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }

    .left {
        width: 60%;

        @media only screen and (max-width: 1000px) {
            width: 100%;
        }

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 1317px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
            }

            .buttons {
                display: flex;
                gap: 1rem;

                .dashboard_btn {
                    margin-top: 2rem;
                    @media only screen and (max-width: 1193px) {
                        padding: 1rem;
                    }
                }
            }
        }

        .visitorList {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            min-height: 405px;
            
            .visitorCard {
                display: flex;
                background-color: #FCFCFC;
                border: 0.1px solid #F1F1F1;
                padding: 1rem;
                border-radius: 5px;
                align-items: center;
                cursor: pointer;


                .left {

                    .name {
                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        h4 {
                            font-size: 1.6rem;
                            font-weight: 600;
                            color: #212121;
                        }

                        .label {
                            background-color: #002547;
                            color: #fff;
                            font-size: 0.9rem;
                            padding: 0.3rem 0.8rem;
                            border-radius: 5px;
                        }
                    }

                    .host {
                        margin-top: 0.5rem;
                        font-size: 1.3rem;
                        color: #505050;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;


                }

                &:hover {
                    background-color: darken($color: #FCFCFC, $amount: 1);
                }
            }
        }
    }

    .right {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media only screen and (max-width: 1000px) {
            width: 100%;
        }

        .preRegVisitors {
            min-height: 300px;
        }
    }
}