@import "styles/abstracts/_variables.scss";

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 10rem;

    .optionsContainer {
        width: 65%;

        &__inputContainer {
            display: flex;
            gap: 0.5rem;
            margin-top: 2rem;
            align-items: flex-start;
            
            &__add {
                background-color:bisque;
                height: 45px;
                width: 45px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 2rem;
                    color: $black;
                }
            }
        }

        &__values {

            ul {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                li {
                    font-size: 1.3rem;
                    background-color: rgb(241, 241, 241);
                    padding: 0.8rem 2rem;
                    border-radius: 20px;
                    display: flex;
                    justify-content: space-between;

                    .removeOption {
                        display: flex;
                        align-items: center;
                        gap: 0.6rem;
                        cursor: pointer;
                        color: rgb(248, 66, 66);

                        p {
                            font-size: 1.1rem;
                            user-select: none;
                        }

                        &:hover {
                            p {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

.footerContainer {
    display: flex;
    justify-content: flex-end;

    
}