@import "styles/abstracts/_variables.scss";
@import "styles/abstracts/_mixins.scss";

.brandingContainer {
    display: flex;

    .settings {
        width: 50%;

        .templateContainer {
            margin: 3rem 0;

            p {
                font-size: 1.2rem;
                font-weight: 500;
                margin-bottom: 1rem;
            }

            .templatesContainer {
                display: flex;
                gap: 2rem;

                .template {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                img {
                    width: 220px;
                    margin-bottom: 5px;
                }

                
            }
        }

        .headlineContainer {
            margin-top: 4rem;

            .headlineForm {
                background-color: #FDFDFD;
                padding: 2rem;
                margin-top: 2rem;
                border: 0.1px solid #EFEFEF;
                border-radius: 5px;

                .colorContainer{
                    margin-top: 2rem;

                    .label {
                        font-size: 1.3rem;
                        color: #363636;
                        margin-bottom: 5px;
                    }

                    .colorPicker {
                        display: flex;
                        gap: 1rem;
                        align-items: flex-start;

                        .colorPreview {
                            content: "";
                            background-color: #e9e9e9;
                            width: 38px;
                            height: 38px;
                            border-radius: 5px;
                            border: 0.1px solid $primary-color;
                            padding: 2px;
                            position: relative;

                            .colorPickerInput {
                                display: none;
                                position: absolute;
                                left: 100%;
                                margin-left: 0.5rem;
                                z-index: 1;
                            }
                        }

                        .picker {
                            position: relative;
                            display: flex;
                            
                            img {
                                width: 35px;
                                cursor: pointer;
                            }
    
                            .colorPickerInput {
                                display: none;
                                position: absolute;
                                margin-left: 50px;
                            }
                        }

                        
                    }
                }
            }
        }

        .showSiteLogoContainer {
            margin-top: 5rem;
        }

        .alignContentContainer {
            margin-top: 5rem;

            p {
                font-size: 1.3rem;
                margin-bottom: 1rem;
                color: $black;
            }

            .inputs {
                display: flex;
                gap: 3rem;
            }
        }
    }

    .preview {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .sticky {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            position: sticky;
            top: 150px;
        }

        .tabletContainer {
            width: 450px;
            height: 330px;
            border-radius: 15px;
            overflow: hidden;
            border: 20px solid rgb(245, 245, 245);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            background-size: cover; /* Adjust as needed: cover, contain, auto, etc. */
            background-position: center; /* Adjust as needed: left, right, top, bottom, etc. */
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            
            

            .headline1 {
                font-weight: 400;
                font-size: 1.2rem;
                color: $secondary-color;
            }

            .headline2 {
                font-size: 1.6rem;
                color: $primary-color;
            }

            .headline3 {
                font-size: 1.1rem;
                color: $secondary-color;
            }

            .logo {
                max-width: 180px;
                margin: 1.5rem 0;
                max-height: 100px;
                width: auto;
                height: auto;
            }
        }

        .template1 {
            width: 450px;
            height: 330px;
            border-radius: 15px;
            overflow: hidden;
            border: 20px solid rgb(245, 245, 245);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

            &__header {
                background-color: #ebebeb;
                height: 75%;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 2rem;
                background-size: cover; /* Adjust as needed: cover, contain, auto, etc. */
                background-position: center; /* Adjust as needed: left, right, top, bottom, etc. */
                background-repeat: no-repeat;
                object-fit: contain;
                position: relative;

                .frostedGlass {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: inherit;
                    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
                }

                .headline1 {
                    font-weight: 400;
                    font-size: 1.2rem;
                    color: $secondary-color;
                }
    
                .headline2 {
                    font-size: 1.6rem;
                    color: $primary-color;
                }
            }

            

            &__footer {
                background-color: #ffffff;
                height: 25%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.5rem 2rem;

                .logo {
                    max-width: 130px;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }

                .headline3 {
                    font-size: 1.1rem;
                    color: $secondary-color;
                    display: flex;
                    justify-content: flex-end;
                    flex: 1;
                }
            }
        }
    }
}

.siteLogo {
    padding-bottom: 5rem;
    border-bottom: 0.1px solid #F0F0F0;

    .logoImageContainer {
        background-color: #FCFCFC;
        display: flex;
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-top: 2rem;
        border: 0.1px solid #F2F2F2;
        overflow: hidden;
        padding: 2rem;
    
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    
    .buttonContainer {
        display: flex;
        gap: 3rem;
        margin-top: 1rem;
    }
}

.welcomeScreenContainer {
    padding: 2rem 0;
    margin-top: 3rem;
}

