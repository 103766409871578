.ReactModal__Overlay {
    background-color: rgba(17, 17, 17, 0.555) !important;
    z-index: 999;
}

.ReactModal__Content {

    @include respond(tab-land) {
        width: 80% !important;
        margin-left: auto;
    }

    @include respond(phone) {
        width: 90% !important;
    }

    &::-webkit-scrollbar {
        display: none;
    }

}

// .ReactModal__Content {
//     /* Other styles */

//     transform: scale(0);
//     opacity: 0;
//     transition: transform 0.5s ease, opacity 0.5s ease;
// }

// .ReactModal__Content.ReactModal__Content--after-open {
//     transform: scale(1);
//     opacity: 1;
// }

// .ReactModal__Content.ReactModal__Content--before-close {
//     transform: scale(0.5);
//     opacity: 0;
// }


