@import "styles/abstracts/_variables.scss";

.menuItem {
    color: #252525;
    text-decoration: none;
    display: flex;
    font-size: 1.3rem;
    gap: 1rem;
    font-weight: 500;
    padding: 1.5rem 3rem;
    cursor: pointer;
    align-items: center;
    margin-top: 2rem;

    &:hover {
        background-color: #EEF7FF;
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
        width: 95%;
    }
    
    span {
        opacity: 1;
        transition: opacity .2s ease-in;
    }
}

.menuItemActive {
    background-color: #EEF7FF;
    color: #002547;
    font-weight: 600;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    width: 95%;
}

.menuItem.expandActive {
    color: #002547;
    font-weight: 600;
}

.subMenu {
    color: #fff;
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease;
}

.subMenu.active {
    max-height: 200px;
}

.subMenu a {
    width: 100%;
    display: block;
    margin-top: 1rem;
    padding: 1rem 0 1rem 5rem;
    font-size: 1.2rem;
    color: #252525;

    &:hover {
        background-color: #EEF7FF;
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
        width: 95%;
    }
}

.subMenuActive {
    background-color: #EEF7FF;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    width: 95% !important;
    font-weight: 600;
    color: #002547 !important;

}